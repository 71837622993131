<template>

<app-panel v-on:close="$emit('close')">

	<template v-if="is.finished">

	<app-panel-title text="Results" />

	<app-panel-item text="Total rows" :count="totalRows" />
	<app-panel-item text="Imported" :count="imported" :count-success="true" />
	<app-panel-item text="Skipped" :count="skipped" :count-error="true" />

	<app-panel-content :space="true">

		<app-button text="Close" v-on:click="$emit('close')" />

	</app-panel-content>

	</template>

	<template v-if="is.selected && !is.finished">

	<app-panel-title text="Summary" />

	<app-panel-text :text="'This file contains a total of ' + totalRows + ' rows.'" />
		
	<app-panel-title text="Required Columns" v-if="data.requiredColumns.length" />

	<app-panel-select v-for="(text, id) in requiredColumns" :key="'rc' + id" v-model="model.columns[id]" :text="text" placeholder="Select column" :options="columns" />

	<app-panel-title text="Optional Columns" v-if="data.requiredColumns.length !== $_.keys(applicableColumns).length" />

	<app-panel-select v-for="(text, id) in optionalColumns" :key="'oc' + id" v-model="model.columns[id]" :text="text" placeholder="Select column" :options="columns" />

	<app-panel-title text="Settings" />

	<app-panel-check :active="model.head" text="Has Column Headers" v-on:toggle="model.head = !model.head" />

	<app-panel-title text="Value Mapping" v-if="$_.keys(data.valueMapping).length" />

	<app-panel-item :disabled="!model.columns[id]" v-for="(value, id) in data.valueMapping" :key="'vm' + id" :caret="true" :text="data.columns[id]" :count="$_.keys(value).length" v-on:click="is.mapping = id" />

	<app-panel-content :space="true">

		<app-button text="Run" v-if="is.selected" :disabled="!validImport" :loading="is.loading" v-on:click="onRunClick" />

	</app-panel-content>

	<app-panel v-if="is.mapping">
		
		<app-panel-title :text="'Mapping ' + data.columns[is.mapping]" />

		<app-panel-select v-for="option in data.valueMapping[is.mapping]" :key="'mv' + option.value" v-model="model.values[is.mapping][option.value]" placeholder="Select value" :text="option.text" :options="valueOptions" />

		<app-panel-content :space="true">

			<app-button text="Back" v-on:click="is.mapping = false" />

		</app-panel-content>

	</app-panel>

	</template>

	<template v-if="!is.selected">
		
	<app-panel-title text="Import" />

	<app-panel-text text="Select the CSV file you wish to upload and then assign the data columns to the related fields." />

	<input ref="file" type="file" accept=".csv" v-on:change="onFileSelected" class="import-file" />

	<app-panel-content>

		<app-button text="Select file" v-if="!is.selected" :loading="is.loading" v-on:click="onSelectClick" />

	</app-panel-content>

	</template>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'
import Vue from 'vue'
import Papa from 'papaparse'

export default {

	mixins: [validationMixin],

	props: ['data'],

	data: function() {

		return {
			is: {
				loading: false,
				selected: false,
				mapping: false,
				finished: false
			},
			model: {
				head: true,
				columns: {},
				values: {}
			},
			import: [],
			skipped: 0,
			imported: 0
		}

	},

	validations: {
		model: {
		}			
	},

	created: function() {

		this.$_.each(this.applicableColumns, function(value, key) {

			Vue.set(this.model.columns, key, false)

		}.bind(this))

		this.$_.each(this.data.valueMapping, function(values, key) {

			Vue.set(this.model.values, key, {})

			this.$_.each(values, function(value) {

				Vue.set(this.model.values[key], value.value, false)

			}.bind(this))

		}.bind(this))

	},

	computed: {

		applicableColumns: function() {

			if (!this.data.exportOnlyColumns) return this.data.columns

			var applicable = {}

			this.$_.each(this.data.columns, function(value, key) {

				if (!this.$_.contains(this.data.exportOnlyColumns, key)) applicable[key] = value

			}.bind(this))

			return applicable

		},

		totalRows: function() {

			return this.import.length - ((this.model.head) ? 1 : 0)

		},

		validImport: function() {

			return !this.$_.filter(this.model.columns, function(value, key) {

				return this.$_.contains(this.data.requiredColumns, key) && value === false

			}.bind(this)).length

		},

		valueOptions: function() {

			var options = {}

			if (this.is.mapping) {

				var columnIndex = this.model.columns[this.is.mapping]

				var rows = this.$_.clone(this.import)

				if (this.model.head) rows = rows.slice(1)

				var values = this.$_.without(this.$_.unique(this.$_.pluck(rows, columnIndex)), undefined, "")

				this.$_.each(values, function(value) {

					options[value] = value

				})

			}

			return options 

		},

		requiredColumns: function() {

			return this.$_.pick(this.applicableColumns, function(value, key) {

				return this.$_.contains(this.data.requiredColumns, key)

			}.bind(this))

		},

		optionalColumns: function() {

			return this.$_.pick(this.applicableColumns, function(value, key) {

				return !this.$_.contains(this.data.requiredColumns, key)

			}.bind(this))

		},

		columns: function() {
			
			var columns = []

			this.$_.each(this.import[0], function(value, index) {

				columns.push((this.model.head) ? value : 'Column ' + index.toString())

			}.bind(this))

			return columns

		}

	},

	methods: {

		onSelectClick: function() {

			this.$refs.file.click()

		},

		onRunClick: function() {

			if (this.validImport) {

				this.is.loading = true

				var rows = this.$_.clone(this.import)
				var models = []

				if (this.model.head) rows = rows.slice(1)

				this.$_.each(rows, function(row) {

					var model = this.$_.clone(this.data.columns)

					this.$_.each(model, function(value, key) {

						model[key] = row[this.model.columns[key]]

						if (this.$_.contains(this.$_.keys(this.data.valueMapping), key)) {

							model[key] = this.$_.findKey(this.model.values[key], function(value) {

								return value === model[key]

							})

						}

					}.bind(this))

					models.push(model)

				}.bind(this))
					
				this.$api.post(this.data.endpoint, {
					models: models
				}).then(function(json) {

					this.imported = json.imported
					this.skipped = json.skipped

					this.is.finished = true
					
					this.is.loading = false

					this.$pubsub.$emit('imported')

				}.bind(this))

			}

		},

		onFileSelected: function(e) {

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.import = Papa.parse(reader.result, {
					skipEmptyLines: true
				}).data

				this.is.selected = true

			}.bind(this)

			reader.readAsText(file)
			
		}

	}

}

</script>

<style scoped>

.import-file {
	display: none;
}

</style>
